<template>
  <b-list-group>
    <b-list-group-item v-for="data in ctitleGroup" :key="data._id">
      <router-link
        class="mb-1 text-primary"
        :to="{
          name: data.hc_name.includes('oc-')
            ? 'dashboard-other-matter-preview'
            : 'dashboard-matter-preview',
          params: { id: data._id }
        }"
        target="_blank"
      >
        <h6 class="mb-50 text-primary">{{ data.title }}</h6>
      </router-link>

      <small class="pb-50" v-if="data.file_no"
        >File No.: {{ data.file_no }}</small
      >

      <b-row class="justify-content-between">
        <b-col class="mr-auto cursor-pointer" @click="navigateToMatter(data)">
          <h5 class="font-weight-bold">{{ getUniqueId(data) }}</h5>
          <small v-if="data.cause_name">[ {{ data.cause_name }} ]</small>
        </b-col>

        <b-col class="text-end" v-if="data.last_order_url">
          <b-row v-if="data.last_order_url" class="justify-content-end">
            <b-badge
              variant="light"
              pill
              class="badge-round cursor-pointer"
              @click="
                exportJudgment({
                  url: data.last_order_url,
                  date: data.last_order.datetime,
                  matter_id: data.unique_id
                })
              "
            >
              <feather-icon
                :id="`order-${data._id}-preview-icon`"
                icon="Link2Icon"
                size="16"
                class="mr-25 text-primary"
              />
              <span class="text-primary">Last Order</span>
            </b-badge>
          </b-row>
        </b-col>
      </b-row>

      <div class="cursor-pointer" @click="navigateToMatter(data)">
        <div v-if="data.ctitle">
          <small class="text-secondary font-italic">{{ data.ctitle }}</small>
        </div>

        <b-row class="justify-content-between px-1">
          <div v-if="data.causelist_type">
            <small class="text-secondary">({{ data.causelist_type }})</small>
          </div>
          <div v-if="data.list_type">
            <small class="text-secondary"
              >(List Type: {{ data.list_type }})</small
            >
          </div>
        </b-row>

        <div v-if="data.stats">
          <small class="text-secondary">{{ getStats(data.stats) }}</small>
        </div>

        <div v-if="data.serial_number || data.court_number">
          <small
            class="text-primary font-weight-bolder text-uppercase"
            v-if="data.court_number || data.serial_number > 0"
            >Listed:</small
          >
          <small v-if="data.serial_number > 0">
            <small class="text-primary font-weight-bolder text-uppercase"
              >[ Sr No. {{ data.serial_number }} ]</small
            >
          </small>
          <small v-if="data.court_number !== null">
            <small v-if="checkIfInteger(data.court_number)">
              <small class="text-primary font-weight-bolder text-uppercase">
                - [ Court No.
                {{
                  data.court_number <= 0
                    ? getStringCourtNumber(data.court_number)
                    : data.court_number
                }}
                ]
              </small>
            </small>
            <small v-else>
              <small class="text-primary font-weight-bolder text-uppercase">
                - [ Court No. {{ data.court_number }} ]
              </small>
            </small>
          </small>
        </div>

        <div v-if="data.judges">
          <small class="text-black font-weight-bold">
            {{
              data.hc_name.includes('hc') || data.hc_name.includes('sci')
                ? data.judges.length > 1
                  ? 'JUSTICES'
                  : 'JUSTICE'
                : ''
            }}
            {{ data.judges.join(', ') }}
          </small>
        </div>

        <div v-if="data.hc_name.includes('hc') || data.hc_name.includes('sci')">
          <div
            v-if="
              Object.keys(data).includes('serial_number') &&
              Object.keys(data).includes('court_number')
            "
          >
            <small v-if="data.court_number !== null && data.serial_number > 0">
              <hearing-display-court
                :date="date"
                :court_id="data.court_id"
                :court_no="
                  Object.keys(data).includes('transferred_court') &&
                  data.transferred_court != null &&
                  data.transferred_court != -1
                    ? data.transferred_court
                    : data.court_number
                "
                :serial_no="data.serial_number"
              ></hearing-display-court>
            </small>
          </div>
        </div>

        <div v-if="Object.keys(data).includes('last_note')">
          <small v-if="data.last_note" class="text-black font-weight-bold">
            Note: {{ data.last_note }}
          </small>
        </div>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
// Your existing imports
import HearingDisplayCourt from '../../hearings/components/HearingDisplayCourt.vue'
import axios from 'axios'
import {
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BBadge,
  BButton,
  BCollapse,
  VBTooltip
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BBadge,
    HearingDisplayCourt,
    BCollapse,
    BButton
    // Include any other components as necessary
  },
  directives: { 'b-tooltip': VBTooltip },
  props: {
    items: {
      type: Array,
      required: true
    },
    hearing_date: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ctitleGroup: this.items,
      date: this.hearing_date
    }
  },
  methods: {
    checkIfInteger(court_number) {
      // Parse the input as a number and check if it's an integer
      const parsedValue = parseInt(court_number, 10)
      return Number.isInteger(parsedValue)
    },
    navigateToDetail(data) {
      this.$router.push({
        name: data.hc_name.includes('oc-')
          ? 'dashboard-other-matter-preview'
          : 'dashboard-matter-preview',
        params: { id: data._id }
      })
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },
    exportJudgment({ url, date, matter_id }) {
      let dd = date.toString().split('T')[0]
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `${uu}-order-${dd}.pdf`)
    },
    getStats(stats) {
      let sList = []
      let sResult = ''

      if (stats != null) {
        if (
          stats.hasOwnProperty('total_fresh_cases') &&
          stats['total_fresh_cases'] !== null &&
          typeof stats['total_fresh_cases'] === 'number' &&
          stats['total_fresh_cases'] > 0
        ) {
          sList.push('F-' + stats['total_fresh_cases'])
        }

        if (
          stats.hasOwnProperty('total_add_cases') &&
          stats['total_add_cases'] !== null &&
          typeof stats['total_add_cases'] === 'number' &&
          stats['total_add_cases'] > 0
        ) {
          sList.push('Add-' + stats['total_add_cases'])
        }

        if (
          stats.hasOwnProperty('total_ia_cases') &&
          stats['total_ia_cases'] !== null &&
          typeof stats['total_ia_cases'] === 'number' &&
          stats['total_ia_cases'] > 0
        ) {
          sList.push('App-' + stats['total_ia_cases'])
        }

        if (
          stats.hasOwnProperty('ordinary') &&
          stats['ordinary'] !== null &&
          typeof stats['ordinary'] === 'number' &&
          stats['ordinary'] > 0
        ) {
          sList.push('Ord-' + stats['ordinary'])
        }

        if (
          stats.hasOwnProperty('urgent') &&
          stats['urgent'] !== null &&
          typeof stats['urgent'] === 'number' &&
          stats['urgent'] > 0
        ) {
          sList.push('Urg-' + stats['urgent'])
        }
      }

      if (sList.length > 0) {
        sResult = '( '
        sResult += sList.join(' ')
        sResult += ' )'
      }

      return sResult
    }
  }
}
</script>

<style scoped>
.text-primary {
  color: #007bff;
}
.text-secondary {
  color: #6c757d;
}
</style>
